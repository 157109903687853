import React from 'react'
import styled from 'styled-components'

const StyledTitleSmall = styled.h2`
  font-weight: ${(props) => props.theme.font.weight.s} !important;
  text-transform: uppercase;

  @media (min-width: 992px) {
    font-size: ${(props) => props.theme.font.size.l} !important;
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.xm} !important;
  }
`

interface TitleSmallProps {
  className?: string
  style?: any
  children: React.ReactNode
}

const TitleSmall: React.FC<TitleSmallProps> = ({
  style = {},
  className = '',
  children,
}) => (
  <StyledTitleSmall className={className} style={style}>
    {children}
  </StyledTitleSmall>
)

export default TitleSmall
