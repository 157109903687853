/* eslint-disable no-undef */
import React, { useState, useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import CustomLink from 'components/shared/CustomLink'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image2'
import Logo from 'components/elements/Logo'
import TitleCompact from 'components/elements/TitleCompact'

const StyledHeader = styled(motion.nav)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 5;

  @media (max-width: 991px) {
    height: 75px !important;
  }
`

const HeaderTop = styled(motion.div)`
  @media (min-width: 992px) {
    border-bottom: 1px solid ${(props) => props.theme.color.face.light};
  }

  @media (max-width: 991px) {
    height: 75px !important;
  }
`

const HeaderMenu = styled.div`
  height: 50px;
`

const Brand = styled(CustomLink)`
  &:hover {
    text-decoration: none;
  }
`

const NavButton = styled.button`
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;

  @media (min-width: 768px) {
    width: 80px;
  }

  @media (max-width: 767px) {
    width: 60px;
  }
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.font.size.m};
  }

  @media (max-width: 767px) {
    font-size: ${(props) => props.theme.font.size.sm};
  }
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.secondary};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

const HeaderTopContent = styled(ParseContent)`
  & .button {
    margin-bottom: 0;
  }

  .button > a,
  .button > button {
    font-size: ${({ theme }) => theme.font.size.sm};
    padding: 5px 15px;
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
  transparent?: boolean
}

const Header: React.FC<HeaderProps> = ({
  isSticky = false,
  setIsScrollable,
  transparent = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useContext(ThemeContext)

  const { fields } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          top {
            description
          }

          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }

          mobile {
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 1140)
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader
      isSticky={isSticky || !transparent}
      animate={isSticky || !transparent ? 'compact' : 'normal'}
      variants={{
        compact: {
          backgroundColor: theme.color.face.main,
          height: 100,
          boxShadow: '0 0 10px rgba(0, 0, 0, .3)',
        },
        normal: {
          backgroundColor: 'transparent',
          height: 125,
          boxShadow: 'none',
        },
      }}
    >
      <HeaderTop
        isSticky={isSticky || !transparent}
        animate={isSticky || !transparent ? 'compact' : 'normal'}
        variants={{
          compact: {
            height: 50,
          },
          normal: {
            height: 75,
          },
        }}
        className="container d-flex justify-content-between align-items-center"
      >
        <Brand to="/">
          <Logo />
        </Brand>

        <div className="d-sm-block d-none">
          <HeaderTopContent content={fields?.header?.top?.description} />
        </div>

        <div className="d-block d-lg-none">
          <NavButton
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(false)
            }}
          >
            <NavButtonStripe />
            <NavButtonStripe />
            <NavButtonStripe />
            <NavButtonTitle>Menu</NavButtonTitle>
          </NavButton>
        </div>
      </HeaderTop>
      <HeaderMenu className="container d-none d-lg-flex align-items-center justify-content-between">
        <div className="pr-lg-5" />
        <MenuDesktop fields={fields} />
      </HeaderMenu>
      <MenuMobile
        fields={fields}
        setIsScrollable={setIsScrollable}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </StyledHeader>
  )
}

const MenuMotion = styled(motion.div)`
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
`

const MenuWrapper = styled(Image)`
  width: 100%;
  height: 100%;
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButtonCloseTitle = styled.div`
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;
  margin-top: 3px;
`

const MenuFragment = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const MenuOverlay = styled.div`
  ${MenuFragment};
  background-color: ${(props) => props.theme.color.face.purple};
  opacity: 0.95;
  z-index: 1;
`

const MenuContent = styled.div`
  ${MenuFragment};
  z-index: 2;
  padding: 50px 0 0;
  overflow: auto;

  & ul {
    padding: 30px 0 30px !important;
    list-style-type: none;
    text-align: center;
    width: 70%;
    margin: 0 auto;

    & li {
      padding: 0;
      border-bottom: 1px solid ${(props) => props.theme.color.face.border};

      &:last-child {
        border-bottom: none;
      }

      & a {
        color: ${(props) => props.theme.color.text.main};
        font-size: ${(props) => props.theme.font.size.m};
        font-weight: ${(props) => props.theme.font.weight.m};
        text-decoration: uppercase;
        display: block;
        padding: 10px 0;
      }
    }
  }
`

const MenuContentList = styled(motion.ul)`
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
`

const MenuContentTop = styled.div`
  & h2,
  & strong {
    color: ${(props) => props.theme.color.text.light} !important;
  }
`

interface MenuMobileProps {
  fields: any
  setIsScrollable: Function
  setIsOpen: Function
  isOpen: boolean
}

const MenuMobile: React.FC<MenuMobileProps> = ({
  fields,
  setIsScrollable,
  setIsOpen,
  isOpen,
}) => {
  const newFields = { ...fields }

  // if (
  //   !newFields.header.menuHeader.find(({ link }: any) => link.title === 'Home')
  // ) {
  //   newFields.header.menuHeader.unshift({
  //     link: {
  //       title: 'Home',
  //       url: '/',
  //     },
  //   })
  // }

  return (
    <div className="d-block d-lg-none">
      <AnimatePresence>
        {isOpen && (
          <MenuMotion
            exit={{
              opacity: 0,
              x: '100%',
            }}
            initial={{
              opacity: 0,
              x: '100%',
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.2,
            }}
          >
            <MenuWrapper
              image={fields.header.mobile.backgroundImage}
              alt="AddComm"
            />
            <MenuOverlay />
            <MenuContent className="pb-5">
              <NavButtonClose
                role="button"
                onClick={() => {
                  setIsOpen(!isOpen)
                  setIsScrollable(true)
                }}
              >
                <NavButtonCloseCross />
                <NavButtonCloseTitle>Sluiten</NavButtonCloseTitle>
              </NavButtonClose>
              <MenuContentTop className="px-5">
                <TitleCompact type="h2">
                  AddComm
                  <br />
                  <strong>Menu</strong>
                </TitleCompact>
              </MenuContentTop>
              <div className="my-4 text-center">
                <HeaderTopContent content={fields?.header?.top?.description} />
              </div>
              <MenuContentList
                className="pt-3 mt-5"
                initial={{
                  x: 300,
                }}
                animate={{
                  x: 0,
                }}
                transition={{
                  delay: 0.1,
                }}
              >
                <Item>
                  <CustomLink to="/">Home</CustomLink>
                </Item>
                <Menu fields={newFields} />
              </MenuContentList>
            </MenuContent>
          </MenuMotion>
        )}
      </AnimatePresence>
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 0;
`

interface MenuDesktopProps {
  fields: any
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ fields }) => (
  <StyledMenuDesktop className="pl-xl-5 ml-xl-5 d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  position: relative;

  & > a {
    padding: 0.25rem 1rem;
    color: ${(props) => props.theme.color.text.secondary};
    font-weight: ${(props) => props.theme.font.weight.xm};
    transition: all 0.2s ease-in-out;

    &[aria-current] {
      background-color: ${(props) => props.theme.color.face.light};
      color: ${({ theme }) => theme.color.text.contrast};
    }

    &:hover {
      background-color: ${(props) => props.theme.color.face.contrast};
      color: ${(props) => props.theme.color.text.light};
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 0.25rem 0.5rem;
    }

    @media (min-width: 992px) {
      border-radius: 25px;
    }
  }
`

interface MenuProps {
  fields: any
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [indexOpen, setIndexOpen] = React.useState<number | null>(null)

  return (
    <>
      {fields.header.menuHeader.map(
        ({ link: { url, title }, submenu }: any, index: number) => {
          const hasSubmenu = submenu !== null && submenu.length > 0

          return (
            <Item
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onMouseEnter={() => {
                if (hasSubmenu) {
                  setIndexOpen(index)
                }
              }}
              onMouseLeave={() => {
                setIndexOpen(null)
              }}
            >
              <CustomLink to={url}>{title}</CustomLink>
              {hasSubmenu && (
                <AnimatePresence>
                  {indexOpen === index && <Submenu menu={submenu} />}
                </AnimatePresence>
              )}
            </Item>
          )
        }
      )}
    </>
  )
}

const StyledSubmenu = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.light};
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  width: 250px;
  overflow: hidden;

  & a {
    color: ${({ theme }) => theme.color.text.purple};
    border-bottom: 1px solid ${(props) => props.theme.color.face.border};
    font-size: ${(props) => props.theme.font.size.sm};
    display: block;

    &:first-child {
      border-top: 1px solid transparent;
    }

    &:not(:hover):last-child {
      border-bottom: 1px solid transparent;
    }

    &[aria-current] {
      font-weight: ${(props) => props.theme.font.weight.l};
      color: ${({ theme }) => theme.color.text.contrast};
    }

    &:hover {
      color: ${({ theme }) => theme.color.text.purple};
      background-color: ${(props) => props.theme.color.face.background};

      &:first-child {
        border-top: 1px solid ${(props) => props.theme.color.face.border};
      }
    }
  }
`

interface SubmenuProps {
  menu: any
}

const Submenu: React.FC<SubmenuProps> = ({ menu }) => (
  <StyledSubmenu
    className="py-3 d-lg-block d-none"
    exit={{
      y: 30,
      opacity: 0,
      display: 'none',
    }}
    initial={{
      y: 30,
      opacity: 0,
      display: 'none',
    }}
    animate={{
      y: 0,
      opacity: 1,
      display: 'block',
    }}
  >
    {menu.map(({ link }: any, index: number) => (
      <CustomLink
        // eslint-disable-next-line react/no-array-index-key
        key={`submenu-${index}`}
        className="px-4 py-2 d-flex align-items-center justify-content-between"
        to={link.url}
      >
        {link.title}
        <Arrow />
      </CustomLink>
    ))}
  </StyledSubmenu>
)

const StyledArrow = styled.svg`
  height: 12px;
  width: 12px;
  margin-left: 0.5rem;
  position: relative;
  left: 0;
  top: 0;
  transition: all 0.15s ease-in-out;

  & path {
    fill: ${(props) => props.theme.color.text.contrast};
  }
`

const Arrow = () => (
  <StyledArrow
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 492.004 492.004"
  >
    Î
    <g>
      <g>
        <path
          d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136
			c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002
			v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864
			c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872
			l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"
        />
      </g>
    </g>
  </StyledArrow>
)

export default Header
