import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'

const StyledButton = styled.span<{ isContrast: boolean }>`
  display: inline-block;

  & > a,
  & > button {
    font-weight: ${(props) => props.theme.font.weight.xm};
    color: ${(props) =>
      props.isContrast
        ? props.theme.color.text.contrast
        : props.theme.color.text.main};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;

    &:hover svg {
      left: 10px;
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.sm};

      &:after {
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
      }
    }
  }
`

interface ButtonDefaultProps {
  isContrast?: boolean
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
  target?: string
}

const ButtonDefault: React.FC<ButtonDefaultProps> = ({
  isContrast = false,
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton
    isContrast={isContrast}
    className={`${className} button-orange-arrow`}
  >
    {isCustom ? (
      <>{children}</>
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
        <Arrow />
      </ButtonShell>
    )}
  </StyledButton>
)

const StyledArrow = styled.svg`
  height: 20px;
  width: 20px;
  margin-left: 0.5rem;
  position: relative;
  left: 0;
  top: 0;
  transition: all 0.15s ease-in-out;

  & path {
    fill: ${(props) => props.theme.color.text.contrast};
  }
`

export const Arrow = () => (
  <StyledArrow
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 492.004 492.004"
  >
    Î
    <g>
      <g>
        <path
          d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136
			c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002
			v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864
			c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872
			l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"
        />
      </g>
    </g>
  </StyledArrow>
)

export default ButtonDefault
