import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'
import { motion } from 'framer-motion'

const StyledButton = styled(motion.span)`
  background-color: transparent;
  display: inline-block;

  & > a,
  & > button {
    border: 2px solid ${(props) => props.theme.color.text.light};
    color: ${(props) => props.theme.color.text.light};
    font-size: ${(props) => props.theme.font.size.sm};
    font-weight: ${(props) => props.theme.font.weight.xm};
    padding: 5px 15px;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 15px;
    position: relative;
    transition: all 0.2s;

    &:hover {
      background-color: ${(props) => props.theme.color.text.light};
      color: ${(props) => props.theme.color.text.contrast};
      text-decoration: none;
    }

    @media (min-width: 992px) {
      padding: 5px 15px;
    }

    @media (max-width: 991px) {
      padding: 3px 7px;

      &:after {
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
      }
    }
  }
`

interface ButttonWhiteProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
  target?: string
}

const ButttonWhite: React.FC<ButttonWhiteProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton
    className={`${className} button`}
    whileTap={{ scale: 0.9 }}
    transition={{
      duration: 0.2,
    }}
  >
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButttonWhite
