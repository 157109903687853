import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Image2Props {
  className?: string
  image: any
  alt?: string
  loading?: 'lazy' | 'eager'
}

const Image2: React.FC<Image2Props> = ({
  className = '',
  image,
  alt = '',
  loading = 'lazy',
}) => {
  if (!image) {
    return null
  }

  if (image.url) {
    if (image.subtype === 'svg+xml') {
      return <img src={image.url} alt={alt} className={className} />
    }

    return (
      <div
        className={`gatsby-image-wrapper gatsby-image-wrapper-constrained ${className}`}
      >
        <picture>
          <img src={image.url} alt={alt} />
        </picture>
      </div>
    )
  }

  if (image?.localFile?.publicURL) {
    return (
      <img className={className} src={image?.localFile?.publicURL} alt={alt} />
    )
  }

  if (!image?.localFile?.childImageSharp?.gatsbyImageData) {
    return null
  }

  return (
    <GatsbyImage
      className={className}
      image={image?.localFile?.childImageSharp?.gatsbyImageData}
      alt={alt}
      loading={loading}
    />
  )
}

export default Image2
