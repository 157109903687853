/* eslint-disable import/extensions */
/* eslint-disable no-underscore-dangle */
import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

interface FlexContentProps {
  prefix?: string
  fields: any
  location?: any
  title?: any
}

interface FlexerProps {
  [key: string]: any
}

interface ComponentProps {
  fields: any
  location?: any
  title?: string
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location = {},
  title = '',
}) => {
  const flexers: FlexerProps = {
    [`${prefix}_Flex_BannerWithImage`]: loadable(
      () => import('components/flex/BannerWithImage')
    ),
    [`${prefix}_Flex_BannerWithImg`]: loadable(
      () => import('components/flex/BannerWithImg')
    ),
    [`${prefix}_Flex_Usp`]: loadable(() => import('components/flex/Usp')),
    [`${prefix}_Flex_ImageWithDescription`]: loadable(
      () => import('components/flex/ImageWithDescription')
    ),
    [`${prefix}_Flex_ClientCase`]: loadable(
      () => import('components/flex/ClientCase')
    ),
    [`${prefix}_Flex_DescriptionWithSvg`]: loadable(
      () => import('components/flex/DescriptionWithSvg')
    ),
    [`${prefix}_Flex_Slider`]: loadable(() => import('components/flex/Slider')),
    [`${prefix}_Flex_DescriptionWithImage`]: loadable(
      () => import('components/flex/DescriptionWithImage')
    ),
    [`${prefix}_Flex_DescriptionWithBlocks`]: loadable(
      () => import('components/flex/DescriptionWithBlocks')
    ),
    [`${prefix}_Flex_Gallery`]: loadable(
      () => import('components/flex/Gallery')
    ),
    [`${prefix}_Flex_DescriptionWithUsps`]: loadable(
      () => import('components/flex/DescriptionWithUsps')
    ),
    [`${prefix}_Flex_Vacancies`]: loadable(
      () => import('components/flex/Vacancies')
    ),
    [`${prefix}_Flex_VacancyForm`]: loadable(
      () => import('components/flex/VacancyForm')
    ),
    [`${prefix}_Flex_VacancyDescription`]: loadable(
      () => import('components/flex/VacancyDescription')
    ),
    [`${prefix}_Flex_Process`]: loadable(
      () => import('components/flex/Process')
    ),
    [`${prefix}_Flex_LatestNews`]: loadable(
      () => import('components/flex/LatestNews')
    ),
    [`${prefix}_Flex_StickyCta`]: loadable(
      () => import('components/flex/StickyCta')
    ),
    [`${prefix}_Flex_BlogDescription`]: loadable(
      () => import('components/flex/BlogDescription')
    ),
    [`${prefix}_Flex_BlogForm`]: loadable(
      () => import('components/flex/BlogForm')
    ),
    [`${prefix}_Flex_BannerWithDescription`]: loadable(
      () => import('components/flex/BannerWithDescription')
    ),
    [`${prefix}_Flex_CtaOnLine`]: loadable(
      () => import('components/flex/CtaOnLine')
    ),
    [`${prefix}_Flex_Cta`]: loadable(() => import('components/flex/Cta')),
    [`${prefix}_Flex_Steps`]: loadable(() => import('components/flex/Steps')),
    [`${prefix}_Flex_Team`]: loadable(() => import('components/flex/Team')),
    [`${prefix}_Flex_Contact`]: loadable(
      () => import('components/flex/Contact')
    ),
    [`${prefix}_Flex_ContactThanks`]: loadable(
      () => import('components/flex/ContactThanks')
    ),
    [`${prefix}_Flex_Blog`]: loadable(() => import('components/flex/Blog')),
    [`${prefix}_Flex_BannerWithSmallImage`]: loadable(
      () => import('components/flex/BannerWithSmallImage')
    ),
    [`${prefix}_Flex_Message`]: loadable(
      () => import('components/flex/Message')
    ),
    [`${prefix}_Flex_ImageLinks`]: loadable(
      () => import('components/flex/ImageLinks')
    ),
    [`${prefix}_Flex_UspBig`]: loadable(() => import('components/flex/UspBig')),
    [`${prefix}_Flex_Map`]: loadable(() => import('components/flex/Map')),
    [`${prefix}_Flex_Text`]: loadable(() => import('components/flex/Text')),
    [`${prefix}_Flex_UspWithTextAndPerson`]: loadable(
      () => import('components/flex/UspWithTextAndPerson')
    ),
    [`${prefix}_Flex_CustomText`]: loadable(
      () => import('components/flex/CustomText')
    ),
    [`${prefix}_Flex_BannerWithVideo`]: loadable(
      () => import('components/flex/BannerWithVideo')
    ),
    [`${prefix}_Flex_TitleWithVideo`]: loadable(
      () => import('components/flex/TitleWithVideo')
    ),
    [`${prefix}_Flex_BlockWithText`]: loadable(
      () => import('components/flex/BlockWithText')
    ),
    [`${prefix}_Flex_Accordion`]: loadable(
      () => import('components/flex/Accordion')
    ),
    [`${prefix}_Flex_Fragment`]: loadable(
      () => import('components/flex/Fragment')
    ),
    [`${prefix}_Flex_Misc`]: loadable(() => import('components/flex/Misc')),
    [`${prefix}_Flex_Downloads`]: loadable(
      () => import('components/flex/Downloads')
    ),
  }

  if (!fields.flex) {
    return null
  }

  Object.keys(flexers).forEach((key) => {
    flexers[key].preload()
  })

  return (
    <>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
            title={title}
          />
        )
      })}
    </>
  )
}

export default FlexContentHandler
