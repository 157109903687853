import React from 'react'
import { Link } from 'gatsby'

const isPartiallyActive = (data: any) => {
  let { isPartiallyCurrent } = data

  // if 'to' location is '/' and current page is home than set partially current
  if (data.location.pathname === '/' && data.href === '/home/') {
    isPartiallyCurrent = true
  }

  const hashSplit = data.href.split('#')

  // if 'to' has hash than custom check partially current
  if (hashSplit[1]) {
    hashSplit[0] = hashSplit[0].replace('/#', '')
    hashSplit[0] = hashSplit[0].replace('#', '')

    if (hashSplit[0] === data.location.pathname) {
      isPartiallyCurrent = true
    }
  }

  if (data.location.pathname !== '/' && data.href === '/') {
    isPartiallyCurrent = false
  }

  return isPartiallyCurrent ? { 'aria-current': `page` } : {}
}

interface CustomLinkProps {
  to: string
  className?: string
  children: any
  target?: string
}

const CustomLink: React.FC<CustomLinkProps> = ({
  to,
  className = '',
  children,
  target = '',
}) => {
  let isAnchor = false

  if (
    to.indexOf('tel:') !== -1 ||
    to.indexOf('mailto:') !== -1 ||
    to.indexOf('/static/') !== -1 ||
    to.indexOf('https://') !== -1
  ) {
    isAnchor = true
  }

  return !isAnchor ? (
    <Link
      target={target}
      getProps={isPartiallyActive}
      to={to}
      className={className}
    >
      {children}
    </Link>
  ) : (
    <a target={target} href={to} className={className}>
      {children}
    </a>
  )
}

export default CustomLink
