import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'

// CSS
import 'styles/all.css'
import TrendPopup from 'components/elements/TrendPopup'

const Content = styled.div<{ isSticky?: boolean }>``

interface LayoutProps {
  children: any
  transparentHeader?: boolean
  location: any
}

const Layout: React.FC<LayoutProps> = ({
  children,
  transparentHeader = true,
  location,
}) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <Header
        isSticky={isSticky}
        setIsScrollable={setIsScrollable}
        transparent={transparentHeader && location.pathname !== '/'}
      />
      <Content isSticky={isSticky}>{children}</Content>
      <Footer />
      {/* {location.pathname === '/' && <TrendPopup />} */}
    </Theme>
  )
}

export default Layout
