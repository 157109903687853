import React, { DOMElement } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { renderToString } from 'react-dom/server'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'
import CustomLink from 'components/shared/CustomLink'

// Elements
import ButtonOrange from 'components/elements/ButtonOrange'
import ButtonOrangeBig from 'components/elements/ButtonOrangeBig'
import ButtonOrangeBigBorder from 'components/elements/ButtonOrangeBigBorder'
import ButtonWhite from 'components/elements/ButtonWhite'
import ButtonOrangeArrow from 'components/elements/ButtonOrangeArrow'
import TitleCompact from 'components/elements/TitleCompact'
import TitleSmall from 'components/elements/TitleSmall'
import TextBlock from 'components/elements/TextBlock'

// Media
import check from 'img/check.svg'
import ButtonBlueBig from 'components/elements/ButtonBlueBig'

const Content = styled.div<{ isLightHs: boolean }>`
  font-weight: ${(props) => props.theme.font.weight.s};
  font-size: ${(props) => props.theme.font.size.m};

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.l};
  }

  & h1 {
    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xxl};
      font-weight: ${(props) => props.theme.font.weight.xl};
      line-height: ${(props) => props.theme.font.size.xxxl};
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xl};
      font-weight: ${(props) => props.theme.font.weight.xl};
      line-height: ${(props) => props.theme.font.size.xxl};
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.s};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.l};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & p {
    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 992px) {
      line-height: ${(props) => props.theme.font.size.xl};
    }

    @media (max-width: 991px) {
      line-height: ${(props) => props.theme.font.size.l};
    }
  }

  & ul {
    list-style-type: none;
    padding: 0;

    & li {
      position: relative;
      padding-left: 40px;
      margin-bottom: 2rem;

      &:before {
        position: absolute;
        content: url(${check});
        left: 0;
        top: 2px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & a {
    line-height: ${(props) => props.theme.font.size.m};
  }

  & img {
  }

  & blockquote {
    font-weight: ${(props) => props.theme.font.weight.s};
    font-style: italic;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5 {
      display: block;
      font-weight: ${(props) => props.theme.font.weight.s};

      @media (min-width: 992px) {
        font-size: ${(props) => props.theme.font.size.xl};
        line-height: ${(props) => props.theme.font.size[40]};
      }

      @media (max-width: 991px) {
        font-size: ${(props) => props.theme.font.size.l};
        line-height: ${(props) => props.theme.font.size.l};
      }
    }
  }

  & .gatsby-image-wrapper {
    img:first-child {
      display: none !important;
    }
  }

  & .button {
    margin-bottom: 0.5rem;
  }

  @media (min-width: 992px) {
    line-height: ${(props) => props.theme.font.size.xl};
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: ${(props) => props.theme.font.size.l};
  }

  & hr {
    border-top: none;
    padding: 0.5rem 0;
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
`

const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const convertCSSKey = (key: string) => {
  const keySplit = key.split('-')
  let newKey = ''

  keySplit.forEach((value, index) => {
    let newValue = value

    if (index > 0) {
      newValue = capitalize(value)
    }

    newKey += newValue
  })

  return newKey
}

const convertCSS = (style: any) => {
  const result: any = []

  if (!style) {
    return result
  }

  const attributes = style.split(';')

  attributes.forEach((attribute: any) => {
    const { 0: key, 1: value } = attribute.split(':')

    if (value) {
      result[convertCSSKey(key)] = value.split(' ').join('')
    }
  })

  return result
}

const findAndReplace = (content: any, settings: any) =>
  parse(content, {
    replace: (domNode: any) => {
      if (domNode.name === 'a' && domNode.attribs.class === 'orange-button') {
        return (
          <ButtonOrange
            to={domNode.attribs.href}
            target={domNode.attribs.target || ''}
          >
            {domToReact(domNode.children, {})}
          </ButtonOrange>
        )
      }
      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'orange-big-button'
      ) {
        return (
          <ButtonOrangeBig
            to={domNode.attribs.href}
            target={domNode.attribs.target || ''}
          >
            {domToReact(domNode.children, {})}
          </ButtonOrangeBig>
        )
      }
      if (domNode.name === 'a' && domNode.attribs.class === 'blue-big-button') {
        return (
          <ButtonBlueBig
            to={domNode.attribs.href}
            target={domNode.attribs.target || ''}
          >
            {domToReact(domNode.children, {})}
          </ButtonBlueBig>
        )
      }
      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'white-button-big'
      ) {
        return (
          <ButtonOrangeBig
            color="white"
            to={domNode.attribs.href}
            target={domNode.attribs.target || ''}
          >
            {domToReact(domNode.children, {})}
          </ButtonOrangeBig>
        )
      }
      if (domNode.name === 'a' && domNode.attribs.class === 'white-button') {
        return (
          <ButtonWhite
            to={domNode.attribs.href}
            target={domNode.attribs.target || ''}
          >
            {domToReact(domNode.children, {})}
          </ButtonWhite>
        )
      }
      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'orange-big-button-border'
      ) {
        return (
          <ButtonOrangeBigBorder
            to={domNode.attribs.href}
            target={domNode.attribs.target || ''}
          >
            {domToReact(domNode.children, {})}
          </ButtonOrangeBigBorder>
        )
      }
      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'white-big-button-border'
      ) {
        return (
          <ButtonOrangeBigBorder isWhite to={domNode.attribs.href}>
            {domToReact(domNode.children, {})}
          </ButtonOrangeBigBorder>
        )
      }
      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'orange-arrow-button'
      ) {
        return (
          <ButtonOrangeArrow
            to={domNode.attribs.href}
            target={domNode.attribs.target || ''}
          >
            {domToReact(domNode.children, {})}
          </ButtonOrangeArrow>
        )
      }
      if (
        (domNode.name === 'h2' || domNode.name === 'h1') &&
        domNode.attribs.class === 'compact-title'
      ) {
        return (
          <TitleCompact
            className={domNode.attribs.class}
            style={
              domNode.attribs.style ? convertCSS(domNode.attribs.style) : {}
            }
            type={domNode.name}
          >
            {domToReact(domNode.children, {})}
          </TitleCompact>
        )
      }
      if (
        (domNode.name === 'h2' || domNode.name === 'h1') &&
        domNode.attribs.class === 'small-title'
      ) {
        return (
          <TitleSmall
            className={domNode.attribs.class}
            style={
              domNode.attribs.style ? convertCSS(domNode.attribs.style) : {}
            }
          >
            {domToReact(domNode.children, {})}
          </TitleSmall>
        )
      }

      if (
        domNode.name === 'table' &&
        domNode.attribs.class.indexOf('text-block') !== -1
      ) {
        const html = renderToString(
          domToReact(domNode.children, {}) as DOMElement<any, any>
        )
        const htmlFindAndReplace = findAndReplace(html, settings)

        return (
          <TextBlock
            className={domNode.attribs.class}
            style={
              domNode.attribs.style ? convertCSS(domNode.attribs.style) : {}
            }
          >
            {htmlFindAndReplace}
          </TextBlock>
        )
      }

      // if (domNode.name === 'img') {
      //   return (
      //     <img style={{ position: 'relative' }} src={domNode.attribs['data-src']} alt="" />
      //   )
      // }

      if (domNode.children && domNode.children.length > 0) {
        const html = renderToString(
          domToReact(domNode.children, {}) as DOMElement<any, any>
        )
        const htmlFindAndReplace = findAndReplace(html, settings)

        const attributes = domNode.attribs

        if (
          attributes.src &&
          attributes.src.indexOf(settings.sourceUrl) === -1
        ) {
          attributes.src = `${settings.sourceUrl}${attributes.src}`
        }

        if (attributes.href) {
          if (
            attributes.href.indexOf('www.') !== -1 ||
            attributes.href.indexOf('http:') !== -1 ||
            attributes.href.indexOf('https:') !== -1
          ) {
            attributes.target = '_blank'
          } else if (
            attributes.href.indexOf('/wp-content/') !== -1 &&
            attributes.href.indexOf(settings.sourceUrl) === -1
          ) {
            attributes.href = `${settings.sourceUrl}${attributes.href}`
          }
        }

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        const Comp = domNode.name

        return (
          <Comp
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            id={attributes.id}
            href={attributes.href}
            target={attributes.target}
          >
            {htmlFindAndReplace}
          </Comp>
        )
      }

      if (domNode.name === 'img') {
        const attributes = domNode.attribs

        if (attributes.src && attributes.src.indexOf('.png') !== -1) {
          return null
        }

        if (
          attributes['data-src'] &&
          attributes['data-src'].indexOf('.png') !== -1
        ) {
          attributes.className = attributes.class

          delete attributes.class

          if (attributes.style) {
            attributes.style = convertCSS(attributes.style)
            attributes.style.position = 'relative'
            attributes.style.opacity = 1
          }

          return (
            <img
              src={attributes['data-src']}
              className={attributes.className}
              style={attributes.style}
              width={attributes.width}
              height={attributes.height}
              alt={attributes.alt}
            />
          )
        }
      }

      if (domNode.name === 'a') {
        const attributes = domNode.attribs

        if (attributes.style) {
          delete attributes.style
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <a {...attributes} target="_blank">
              {domToReact(domNode.children, {})}
            </a>
          )
        }

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <CustomLink to={attributes.href} {...attributes}>
            {domToReact(domNode.children, {})}
          </CustomLink>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content: any
  className?: string
  isLightHs?: boolean
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
  isLightHs = false,
}) => {
  const { site } = useStaticQuery<GatsbyTypes.ParseContentQueryQuery>(graphql`
    query ParseContentQuery {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if (site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  if (!content) {
    return null
  }

  return (
    <Content className={className} isLightHs={isLightHs}>
      {findAndReplace(content, { sourceUrl })}
    </Content>
  )
}

export default ParseContent
