import React from 'react'
import styled from 'styled-components'

const StyledTextBlock = styled.table`
  background-color: ${(props) => props.theme.color.face.border};
  border-radius: 25px;

  & tr > td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    vertical-align: top;

    &:first-child {
      padding-left: 2.5rem;
    }

    &:last-child {
      padding-right: 2.5rem;
    }

    & img {
      max-width: 95%;
      object-fit: contain !important;
    }

    & span.inline-gatsby-image-wrapper > img {
      display: none;
    }

    @media (max-width: 991px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    @media (max-width: 767px) {
      display: block;
      padding-left: 1rem;
      padding-right: 1rem;
      width: 100% !important;

      & .inline-gatsby-image-wrapper {
        max-width: 60%;
      }

      & h1,
      & h2,
      & h3,
      & h4,
      & h5 {
        margin-top: 0;
      }
    }
  }

  @media (min-width: 992px) {
    margin-left: -40px;
    margin-right: -40px;
  }

  @media (max-width: 991px) {
    margin-left: -10px;
    margin-right: -10px;
  }
`

interface TextBlockProps {
  className?: string
  children: React.ReactNode
}

const TextBlock: React.FC<TextBlockProps> = ({ className = '', children }) => (
  <StyledTextBlock className={`${className} my-5`}>{children}</StyledTextBlock>
)

export default TextBlock
