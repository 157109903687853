import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet-async'

// Icon
import favicon from 'img/favicon.png'

const removeHTML = (content: string) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

interface SEOProps {
  seo: any
}

const SEO: React.FC<SEOProps> = ({ seo }) => {
  const image: any = seo.opengraphImage
    ? {
        url: seo.opengraphImage.sourceUrl,
        width: 800,
        height: 600,
        alt: seo.opengraphTitle,
      }
    : ''

  return (
    <>
      <GatsbySeo
        language="nl"
        title={removeHTML(seo.title) || ''}
        description={removeHTML(seo.metaDesc) || ''}
        canonical={seo.canonical || ''}
        openGraph={{
          type: seo.opengraphTitle || '',
          url: seo.opengraphUrl || '',
          title: removeHTML(seo.title) || '',
          description: removeHTML(seo.metaDesc) || '',
          images: [image],
        }}
      />
      <Helmet>
        <link rel="icon" href={favicon} />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="5babdb02-70a9-4c55-9196-859412ed4453"
          type="text/javascript"
          async
        />
        { /* Start of HubSpot Embed Code */ }
        <script 
          type="text/javascript" 
          id="hs-script-loader" 
          async 
          defer 
          src="//js-eu1.hs-scripts.com/25574029.js"
        />
        { /* End of HubSpot Embed Code */}
      </Helmet>
    </>
  )
}

export default SEO
