import React from 'react'
import CustomLink from 'components/shared/CustomLink'
import scrollTo from 'gatsby-plugin-smoothscroll'

interface ButtonShellProps {
  isAnchor?: boolean
  to: string
  children: any
  className?: string
  target?: string
}

const ButtonShell: React.FC<ButtonShellProps> = ({
  isAnchor = false,
  to,
  children,
  className = '',
  target = '',
}) => {
  let isAnchorNew = isAnchor
  const jsto = to.indexOf('jsto:') !== -1

  if (jsto) {
    const newTo = to.split('jsto:')[1]

    return (
      <button
        type="button"
        onClick={() => {
          // eslint-disable-next-line no-eval
          eval(newTo)
        }}
        className={className}
      >
        {children}
      </button>
    )
  }

  const scroller = to.indexOf('#') !== -1

  if (scroller) {
    const newTo = to.split('#')[1]

    return (
      <button
        type="button"
        onClick={() => {
          scrollTo(`#${newTo}`)
        }}
        className={className}
      >
        {children}
      </button>
    )
  }

  if (
    to.indexOf('tel:') !== -1 ||
    to.indexOf('mailto:') !== -1 ||
    to.indexOf('/static/') !== -1 ||
    to.indexOf('https://') !== -1
  ) {
    isAnchorNew = true
  }

  return !isAnchorNew ? (
    <CustomLink to={to} className={className} target={target}>
      {children}
    </CustomLink>
  ) : (
    <a href={to} className={className} target={target}>
      {children}
    </a>
  )
}

export default ButtonShell
