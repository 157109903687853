/* eslint-disable no-undef */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Facebook from 'img/Facebook.inline.svg'
import Linkedin from 'img/Linkedin-logo.inline.svg'
import Instagram from 'img/insta.inline.svg'
import ButtonOrangeBig from 'components/elements/ButtonOrangeBig'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.secondary};
`

const MenuTitle = styled.h3`
  font-size: ${(props) => props.theme.font.size.xm};
  font-weight: ${(props) => props.theme.font.weight.l};
`

const MenuItem = styled(Link)`
  color: ${(props) => props.theme.color.text.light};
  font-weight: ${(props) => props.theme.font.weight.s};
  display: block;
  margin-bottom: 0.25rem;

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.color.text.light};
  }
`

const FooterIconAnchor = styled.a`
  font-size: 0;
`

const FooterIcon = styled.img`
  @media (min-width: 992px) {
    /* height: 115px; */
    width: 115px;
  }

  @media (max-width: 991px) {
    /* height: 75px; */
    width: 75px;
  }
`

const FooterContact = styled.div`
  @media (min-width: 576px) {
    border-left: 1px solid ${(props) => props.theme.color.face.light};
  }

  & img {
    object-fit: contain !important;
  }

  & a {
    color: ${(props) => props.theme.color.text.light};

    &:hover {
      color: ${(props) => props.theme.color.text.light};
      text-decoration: underline;
    }
  }
`

const Footer = () => {
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuOne {
            title
            menu {
              link {
                title
                url
              }
            }
          }
          menuTwo {
            title
            menu {
              link {
                title
                url
              }
            }
          }
          menuThree {
            title
            menu {
              link {
                title
                url
              }
            }
          }
          menuFour {
            title
            description
            linkedinUrl
            instagramUrl
            facebookUrl
          }
          icons {
            image {
              localFile {
                publicURL
              }
            }
            alt
            link {
              title
              url
              target
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter id="footer">
      <div className="container py-5">
        <div className="row py-4">
          <div className="col-sm-6 col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <MenuTitle>{fields?.footer?.menuOne?.title}</MenuTitle>
                <div className="mt-4">
                  {fields?.footer?.menuOne?.menu?.map((edge, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={index} to={edge?.link?.url || ''}>
                      {edge?.link?.title}
                    </MenuItem>
                  ))}
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-md-0 mt-5">
                <MenuTitle>{fields?.footer?.menuTwo?.title}</MenuTitle>
                <div className="mt-4">
                  {fields?.footer?.menuTwo?.menu?.map((edge, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={index} to={edge?.link?.url || ''}>
                      {edge?.link?.title}
                    </MenuItem>
                  ))}
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-lg-0 mt-5">
                <MenuTitle>{fields?.footer?.menuThree?.title}</MenuTitle>
                <div className="mt-4">
                  {fields?.footer?.menuThree?.menu?.map(
                    (edge, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem key={index} to={edge?.link?.url || ''}>
                        {edge?.link?.title}
                      </MenuItem>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-5">
              {fields?.footer?.icons?.map((edge, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="col-sm-6 col-md-3 mb-md-0 mb-4" key={index}>
                  {edge?.link?.url ? (
                    <FooterIconAnchor
                      href={edge?.link?.url}
                      target={edge?.link?.target}
                      rel="noreferrer"
                    >
                      <FooterIcon
                        src={edge?.image?.localFile?.publicURL}
                        alt={edge?.alt || 'Addcomm'}
                      />
                      {edge?.link?.title}
                    </FooterIconAnchor>
                  ) : (
                    <FooterIcon
                      src={edge?.image?.localFile?.publicURL}
                      alt={edge?.alt || 'Addcomm'}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <FooterContact className="col-sm-6 col-md-4 col-lg-3 pl-4 pl-sm-5 mt-5 mt-sm-0">
            <MenuTitle>{fields?.footer?.menuFour?.title}</MenuTitle>
            <div className="mt-4">
              <ParseContent content={fields?.footer?.menuFour?.description} />
            </div>
            <div className="mt-4 d-flex align-items-center">
              <a
                href={fields?.footer?.menuFour?.facebookUrl}
                rel="noreferrer"
                target="_blank"
                className="mr-3"
              >
                <Facebook />
              </a>
              <a
                href={fields?.footer?.menuFour?.instagramUrl}
                rel="noreferrer"
                target="_blank"
                className="mr-3"
              >
                <Instagram />
              </a>
              <a
                href={fields?.footer?.menuFour?.linkedinUrl}
                rel="noreferrer"
                target="_blank"
              >
                <Linkedin />
              </a>
            </div>
            <div className="mt-4 pt-lg-3">
              <ButtonOrangeBig to="/contact">Neem contact op</ButtonOrangeBig>
            </div>
          </FooterContact>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
