import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'

export const ButtonOrangeBigCSS = css<{ color?: string }>`
  background-color: transparent;
  display: inline-block;

  & > a,
  & > button {
    line-height: ${({ theme }) => theme.font.size.m};

    ${(props) =>
      (!props.color || props.color === 'orange') &&
      css`
        border: 2px solid ${props.theme.color.face.contrast};
        background-color: ${props.theme.color.face.contrast};
        color: ${props.theme.color.text.main};
      `};

    ${(props) =>
      props.color === 'white' &&
      css`
        border: 2px solid ${props.theme.color.face.light};
        background-color: ${props.theme.color.face.light};
        color: ${props.theme.color.text.main};
      `};

    ${(props) =>
      props.color === 'blue' &&
      css`
        border: 2px solid ${props.theme.color.face.main};
        background-color: ${props.theme.color.face.main};
        color: ${props.theme.color.text.light};
      `};

    font-size: ${(props) => props.theme.font.size.m};
    font-weight: ${(props) => props.theme.font.weight.m};
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 25px;
    position: relative;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${(props) => props.theme.color.text.main};

      ${(props) =>
        (!props.color || props.color === 'orange') &&
        css`
          border: 2px solid ${props.theme.color.face.blue};
          background-color: ${props.theme.color.face.blue};
          color: ${props.theme.color.text.main};
        `};

      ${(props) =>
        props.color === 'white' &&
        css`
          border: 2px solid ${props.theme.color.face.blue};
          background-color: ${props.theme.color.face.blue};
          color: ${props.theme.color.text.main};
        `};

      ${(props) =>
        props.color === 'blue' &&
        css`
          border: 2px solid ${props.theme.color.face.blue};
          background-color: ${props.theme.color.face.blue};
          color: ${props.theme.color.text.light};
        `};
    }

    @media (min-width: 992px) {
      padding: 13px 30px;
    }

    @media (max-width: 991px) {
      padding: 8px 15px;

      &:after {
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
      }
    }
  }
`

const StyledButton = styled.span<{ color: string }>`
  ${ButtonOrangeBigCSS};
`

interface ButtonOrangeBigProps {
  color?: string
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
  target?: string
}

const ButtonOrangeBig: React.FC<ButtonOrangeBigProps> = ({
  color = 'orange',
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton color={color} className={`${className} button`}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonOrangeBig
