import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'

export const ButtonOrangeBigBorderCSS = css<{ isWhite?: boolean }>`
  background-color: transparent;
  display: inline-block;

  & > a,
  & > button {
    border: 2px solid
      ${(props) =>
        props.isWhite
          ? props.theme.color.text.light
          : props.theme.color.text.contrast};
    color: ${(props) => props.theme.color.text.main};
    font-size: ${(props) => props.theme.font.size.m};
    font-weight: ${(props) => props.theme.font.weight.m};
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 25px;
    position: relative;
    transition: all 0.3s ease-in-out;

    &:hover {
      border: 2px solid ${(props) => props.theme.color.face.blue};
      background-color: ${(props) => props.theme.color.face.blue};
      color: ${(props) => props.theme.color.text.main};
    }

    @media (min-width: 992px) {
      padding: 13px 30px;
    }

    @media (max-width: 991px) {
      padding: 8px 15px;

      &:after {
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
      }
    }
  }
`

const StyledButton = styled.span<{ isWhite: boolean }>`
  ${ButtonOrangeBigBorderCSS};
`

interface ButtonOrangeBigBorderProps {
  isWhite?: boolean
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
  target?: string
}

const ButtonOrangeBigBorder: React.FC<ButtonOrangeBigBorderProps> = ({
  isWhite = false,
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton isWhite={isWhite} className={`${className} button`}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonOrangeBigBorder
