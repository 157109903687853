import React from 'react'
import styled from 'styled-components'

const StyledLogo = styled.div`
  font-family: ${(props) => props.theme.font.family.main};
  font-weight: ${(props) => props.theme.font.weight.l};
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.font.size.xl};
  }

  @media (max-width: 767px) {
    font-size: ${(props) => props.theme.font.size.l};
  }
`

const Logo = () => <StyledLogo>AddComm</StyledLogo>

export default Logo
