const mainTheme = {
  color: {
    face: {
      main: '#01092F',
      contrast: '#EB6209',
      contrastLight: '#FF7B1D',
      secondary: '#D1D4DD',
      background: '#F6F6FC',
      blue: '#02D9ED',
      purple: '#332A7C',
      light: '#FFFFFF',
      border: '#E7E7F7',
    },

    text: {
      main: '#01092F',
      contrast: '#FF7B1C',
      contrastLight: '#FF7B1D',
      secondary: '#FFFFFF',
      purple: '#332A7C',
      light: '#FFFFFF',
      grey: '#A3A3A3',
    },
  },

  font: {
    family: {
      main: 'Roboto, sans-serif',
      secondary: 'Verdana, Arial',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '20px',
      l: '24px',
      28: '28px',
      xl: '32px',
      40: '40px',
      xxl: '50px',
      60: '60px',
      xxxl: '65px',
    },

    weight: {
      xs: 100,
      s: 300,
      m: 400,
      xm: 500,
      l: 600,
      xl: 900,
    },
  },
}

export default mainTheme
