import React from 'react'
import styled, { css } from 'styled-components'

const StyledTitleCompactCSS = css`
  font-weight: ${(props) => props.theme.font.weight.s} !important;
  color: ${(props) => props.theme.color.text.purple};
  text-transform: uppercase;

  & strong {
    font-weight: ${(props) => props.theme.font.weight.l} !important;
    color: ${(props) => props.theme.color.text.main};
    position: relative;

    @media (min-width: 1200px) {
      top: -15px;
    }

    @media (max-width: 1199px) {
      top: -15px;
    }

    @media (min-width: 768px) {
      margin-left: 4.5rem;
    }

    @media (max-width: 767px) {
      margin-left: 1.5rem;
      top: -10px;
    }
  }

  @media (min-width: 1200px) {
    font-size: ${(props) => props.theme.font.size[60]} !important;
    line-height: 52px !important;
  }

  @media (max-width: 1199px) {
    font-size: ${(props) => props.theme.font.size.xxl} !important;
    line-height: 45px !important;
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size[40]} !important;
    line-height: 35px !important;
  }

  @media (max-width: 767px) {
    font-size: ${(props) => props.theme.font.size.xl} !important;
    line-height: 30px !important;
  }

  @media (max-width: 575px) {
    font-size: ${(props) => props.theme.font.size.l} !important;
    line-height: 25px !important;
  }
`

const StyledTitleCompactH1 = styled.h1`
  ${StyledTitleCompactCSS}
`

const StyledTitleCompactH2 = styled.h2`
  ${StyledTitleCompactCSS}
`

interface TitleCompactStyleProps {
  [key: string]: string
}
interface TitleCompactProps {
  className?: string
  style?: TitleCompactStyleProps
  children: React.ReactNode
  type?: 'h1' | 'h2'
}

const TitleCompact: React.FC<TitleCompactProps> = ({
  className = '',
  children,
  style = {},
  type = 'h1',
}) =>
  type === 'h1' ? (
    <StyledTitleCompactH1 className={`${className} mb-lg-3 mb-2`} style={style}>
      {children}
    </StyledTitleCompactH1>
  ) : (
    <StyledTitleCompactH2 className={`${className} mb-lg-3 mb-2`} style={style}>
      {children}
    </StyledTitleCompactH2>
  )

export default TitleCompact
